import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { authService } from "src/services/auth";
import { StorageKeys } from "src/utils/storageKeys";
import Lockr from "lockr";
import { HTTPSTATUSCODES } from "src/utils/constants";
import { TenantDeploymentDTO } from "src/types/tenant";
import { useStyles } from "src/theme/styles";

const LoginJWT: FC = () => {
  const { t } =
    typeof jest !== "undefined" ? { t: (s) => s } : useTranslation();
  let isSubmitting = false;
  const { loginAAD } = useAuth() as any;
  const { verify } = useAuth() as any;
  const classes = useStyles();
  const [loginUnsuccessfully, setLoginUnsuccessfully] = useState(false);
  const navigate = useNavigate();
  const [deployments, setDeployments] = useState<TenantDeploymentDTO[]>([]);
  const [selectedDeployment, setSelectedDeployment] =
    useState<TenantDeploymentDTO | null>(null);

  const defaultProps = {
    options: deployments,
    getOptionLabel: (option: TenantDeploymentDTO) => option.tenantName,
  };

  useEffect(() => {
    Lockr.set(StorageKeys.LoginTenant, "");
  });

  const onClickAAD = async (): Promise<void> => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const tenantId = urlParams.get("id");
      if (tenantId) {
        Lockr.set(StorageKeys.LoginTenant, tenantId);
        localStorage.removeItem(StorageKeys.DeploymentTenantId);
        localStorage.removeItem(StorageKeys.DeploymentTenantKey);
        localStorage.removeItem(StorageKeys.ParentTenantId);
      }

      isSubmitting = true;
      setLoginUnsuccessfully(false);

      const loginResponse = await loginAAD();

      if (loginResponse) {
        // if tenant id suplied in query string, proceed with log in
        // if not, get the list of tenant deployments and let the user choose the tenant
        if (tenantId) {
          await onVerifyAuth();
        } else {
          await getAllDeployments();
        }
      } else {
        setLoginUnsuccessfully(true);
      }

      isSubmitting = false;
    } catch (err) {
      isSubmitting = false;
      if (!jest) {
        console.log(err);
      }
    }
  };

  const onVerifyAuth = async (): Promise<void> => {
    try {
      isSubmitting = true;
      setLoginUnsuccessfully(false);

      const verifyResponse = await verify();
      if (verifyResponse) {
        navigate("/calls/queues");
        setTimezone();
      } else {
        setLoginUnsuccessfully(true);
      }

      isSubmitting = false;
    } catch (err) {
      isSubmitting = false;
      if (!jest) {
        console.log(err);
      }
    }
  };

  const setTimezone = async (): Promise<void> => {
    try {
      const response = await authService.getTimezone();
      if (response) {
        Lockr.set(StorageKeys.Timezone, response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getAllDeployments = async (): Promise<void> => {
    try {
      const response = await authService.getDeployments();

      if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
        setDeployments(response.data);

        const cachedDeploymentKey = localStorage.getItem(
          StorageKeys.DeploymentTenantKey
        );

        if (response.data.length === 0) {
          // clear deployment info if cached
          if (cachedDeploymentKey) {
            clearCachedDeployment();
          }

          await onVerifyAuth();

          return;
        }

        // cache first deployment data if missing
        if (!cachedDeploymentKey) {
          cacheDeployment(response.data[0]);

          if (response.data.length === 1) {
            await onVerifyAuth();
          }

          return;
        }

        // do not show deployments select if only one deployment found
        if (response.data.length === 1) {
          await onVerifyAuth();

          return;
        }

        const cachedDeployment = response.data.find(
          (x) =>
            `${x.deployment.id}:${x.tenantId}:${x.azureTenantId}:${x.parentTenantId}` ===
            cachedDeploymentKey
        );

        // deployment does not exists anymore, clear cache
        if (!cachedDeployment) {
          clearCachedDeployment();

          return;
        }

        setSelectedDeployment(cachedDeployment);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeploymentChange = (event: any, value: TenantDeploymentDTO) => {
    cacheDeployment(value);
  };

  const clearCachedDeployment = () => {
    localStorage.removeItem(StorageKeys.DeploymentTenantKey);
    localStorage.removeItem(StorageKeys.DeploymentTenantId);
    localStorage.removeItem(StorageKeys.ParentTenantId);
  };

  const cacheDeployment = (tenantDeployment: any) => {
    localStorage.setItem(
      StorageKeys.DeploymentTenantKey,
      `${tenantDeployment.deployment.id}:${tenantDeployment.tenantId}:${tenantDeployment.azureTenantId}:${tenantDeployment.parentTenantId}`
    );

    localStorage.setItem(
      StorageKeys.DeploymentTenantId,
      tenantDeployment.tenantId
    );

    localStorage.setItem(
      StorageKeys.ParentTenantId,
      tenantDeployment.parentTenantId
    );

    setSelectedDeployment(tenantDeployment);
  };

  return (
    <>
      {deployments.length > 1 && (
        <Grid container spacing={3}>
          <Grid item md={12} sm={12} xs={12}>
            <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
              <Autocomplete
                {...defaultProps}
                id="deployments"
                groupBy={(option) => option.deployment.name}
                className={classes.loginCompany}
                renderInput={(params) => (
                  <TextField {...params} label={t("DashBoardSidebar.Tenant")} />
                )}
                value={selectedDeployment}
                onChange={handleDeploymentChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Box sx={{ mt: 2 }}>
        {loginUnsuccessfully && (
          <Box sx={{ my: 1 }}>
            <FormHelperText error>
              {t("Login.UnauthorizedUserError")}
            </FormHelperText>
          </Box>
        )}

        <Button
          color="primary"
          disabled={isSubmitting}
          fullWidth
          data-testid="loginAad"
          size="large"
          type="button"
          onClick={deployments.length > 1 ? onVerifyAuth : onClickAAD}
          variant="contained"
        >
          {t("Login.LogInAzureActiveDirectory")}
        </Button>
      </Box>
    </>
  );
};

export default LoginJWT;
