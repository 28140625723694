import { useEffect, useState } from "react";
import type { FC } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Drawer } from "@mui/material";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../theme/styles";
import { generalSettingsService } from "src/services/generalSettings";
import { Assignment as AssignmentIcon, ArticleOutlined as ArticleOutlinedIcon } from '@mui/icons-material';
import { MenuRoutes } from "src/types/routes";
import { DashboardTranslationKeys, HTTPSTATUSCODES, Roles } from "src/utils/constants";
import { getCurrentUserRoles } from "src/utils/jwt-helper";
import { Permissions } from "src/types/settings";
import { MailTemplate24Regular } from "@fluentui/react-icons";
import { StorageKeys } from "src/utils/storageKeys";
import Lockr from 'lockr';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const COLOR = "rgba(192,213,220,255)";

const IconsStyle = {
  width: "18px",
  marginRight: "8px",
  color: COLOR
};

const SkillsIconStyle = {
  width: "28px",
  marginLeft: "-5px",
  marginRight: "3px",
  color: COLOR
};

const TemplatesIconStyle = {
  marginLeft: "-2px",
  marginRight: "3px",
  color: COLOR
};

const sidebarWidth = 320;

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const classes = useStyles();
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { t } = useTranslation();
  const [selectedMenuPath, setSelectedMenuPath] = useState<string>(location.pathname);
  const [permissions, setPermissions] = useState<Permissions>();

  const getPermissions = async () => {
    try {
        const response = await generalSettingsService.getPermissions();

        if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
          setPermissions(response.data);
          Lockr.set(StorageKeys.Permissions, response.data);
        }
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPermissions().catch(console.error);
  }, []);

  const settingsRoutes = [MenuRoutes.GeneralSettings, MenuRoutes.Schedules,
  MenuRoutes.MediaFiles, MenuRoutes.Events,
  MenuRoutes.WrapUp];

  const isSettingsOpen = () => settingsRoutes.reduce(
    (finalVal, currentVal) => finalVal || selectedMenuPath.includes(currentVal)
    , false);

  const mainMenuSections = [
    {
      title: "",
      roles: [Roles.WebAdmin, Roles.Supervisor, Roles.AgentPlus, Roles.CC4AllAdmin],
      items: [
        {
          title: t("DashBoardSidebar.Dashboard"),
          path: MenuRoutes.Dashboard,
          icon: (
            <img
              alt="DashboardIcon"
              src="/static/menu/DashboardMenuIcon.svg"
              style={IconsStyle}
            />
          ),
        },
      ],
      onClick: () => setSelectedMenuPath(MenuRoutes.Dashboard)
    },
    {
      title: "",
      roles: [Roles.WebAdmin, Roles.Supervisor, Roles.AgentPlus, Roles.CC4AllAdmin],
      items: [
        {
          title: t("DashBoardSidebar.Calls"),
          path: MenuRoutes.Calls,
          icon: (
            <img
              alt="TelephonyIcon"
              src="/static/menu/CallsMenuIcon.svg"
              style={IconsStyle}
            />
          ),
          children: [
            {
              title: t(DashboardTranslationKeys.Queues),
              path: `${MenuRoutes.Calls}/${MenuRoutes.Queues}`,
              roles: [Roles.WebAdmin, Roles.Supervisor, Roles.AgentPlus, Roles.CC4AllAdmin]
            },
            {
              title: t("DashBoardSidebar.Flows"),
              path: `${MenuRoutes.Calls}/${MenuRoutes.Flows}`,
              roles: [Roles.WebAdmin]
            },
            {
              title: t("DashBoardSidebar.ResourceAccounts"),
              path: `${MenuRoutes.Calls}/${MenuRoutes.EntryPoints}`,
              roles: [Roles.WebAdmin]
            },
          ],
        },
      ],
      open: selectedMenuPath.includes(MenuRoutes.Calls),
      onClick: () => setSelectedMenuPath(MenuRoutes.Calls)
    },
    {
      title: "",
      roles: [Roles.WebAdmin, Roles.Supervisor, Roles.AgentPlus, Roles.CC4AllAdmin],
      items: [
        {
          title: t("DashBoardSidebar.Generic"),
          path: MenuRoutes.Generic,
          icon: (
            <AssignmentIcon style={{ ...IconsStyle }} fontSize="large" />
          ),
          children: [
            {
              title: t(DashboardTranslationKeys.Queues),
              path: `${MenuRoutes.Generic}/${MenuRoutes.Queues}`,
              roles: [Roles.WebAdmin, Roles.Supervisor, Roles.AgentPlus, Roles.CC4AllAdmin]
            },
            {
              title: t("DashBoardSidebar.EntryPoints"),
              path: `${MenuRoutes.Generic}/${MenuRoutes.EntryPoints}`,
              roles: [Roles.WebAdmin]
            },
          ],
        },
      ],
      open: selectedMenuPath.includes(MenuRoutes.Generic),
      display: permissions?.genericChannelEnabled ? "block" : "none",
      onClick: () => setSelectedMenuPath(MenuRoutes.Generic)
    },
    {
      title: "",
      roles: [Roles.WebAdmin, Roles.Supervisor, Roles.AgentPlus, Roles.CC4AllAdmin],
      items: [
        {
          title: t("DashBoardSidebar.Text"),
          path: MenuRoutes.Text,
          icon: (
            <ArticleOutlinedIcon style={{ ...IconsStyle }} fontSize="large" />
          ),
          children: [
            {
              title: t(DashboardTranslationKeys.Queues),
              path: `${MenuRoutes.Text}/${MenuRoutes.Queues}`,
              roles: [Roles.WebAdmin, Roles.Supervisor, Roles.AgentPlus, Roles.CC4AllAdmin]
            },
            {
              title: t("DashBoardSidebar.Flows"),
              path: `${MenuRoutes.Text}/${MenuRoutes.Flows}`,
              roles: [Roles.WebAdmin],
              sx: { display: permissions?.textFlowsEnabled ? "block" : "none" }
            },
            {
              title: t("DashBoardSidebar.ResourceAccounts"),
              path: `${MenuRoutes.Text}/${MenuRoutes.EntryPoints}`,
              roles: [Roles.WebAdmin]
            },
          ],
        },
      ],
      open: selectedMenuPath.includes(MenuRoutes.Text),
      display: permissions?.textChannelEnabled ? "block" : "none",
      onClick: () => setSelectedMenuPath(MenuRoutes.Text)
    }
  ];

  const getIcon = (iconName: string) => {
    return (
      <img
        alt={iconName}
        src={`/static/menu/${iconName}.svg`}
        style={iconName === 'SkillsMenuIcon' ? SkillsIconStyle : IconsStyle}
      />
    );
  }

  const secondaryMenuSections = [
    {
      title: "",
      roles: [Roles.WebAdmin],
      items: [
        {
          title: t("DashBoardSidebar.Agents"),
          path: MenuRoutes.Users,
          icon: getIcon("AgentsMenuIcon")
        },
      ],
      onClick: () => setSelectedMenuPath(MenuRoutes.Users)
    },
    {
      title: "",
      items: [
        {
          title: t("DashBoardSidebar.Settings"),
          icon: getIcon("SettingsMenuIcon"),
          roles: [Roles.WebAdmin, Roles.Supervisor],
          children: [
            {
              title: t("DashBoardSidebar.General"),
              path: MenuRoutes.GeneralSettings,
              icon: getIcon("SettingsMenuIcon"),
              roles: [Roles.WebAdmin]
            },
            {
              title: t("DashBoardSidebar.ImportContacts"),
              path: MenuRoutes.Contacts,
              icon: getIcon("ContactImportIcon"),
              roles: [Roles.WebAdmin, Roles.Supervisor]
            },
            {
              title: t("DashBoardSidebar.Skills"),
              path: MenuRoutes.Skills,
              icon: getIcon("SkillsMenuIcon"),
              roles: [Roles.WebAdmin],
              sx: { display: permissions?.skillsEnabled ? "block" : "none" }
            },
            {
              title: t("DashBoardSidebar.MediaFiles"),
              path: MenuRoutes.MediaFiles,
              icon: getIcon("MediaFilesMenuIcon"),
              roles: [Roles.WebAdmin]
            },
            {
              title: t("DashBoardSidebar.Templates"),
              path: MenuRoutes.Templates,
              icon: <MailTemplate24Regular style={TemplatesIconStyle} />,
              roles: [Roles.WebAdmin],
              sx: { display: permissions?.textChannelEnabled ? "block" : "none" }
            },
            {
              title: t("DashBoardSidebar.AIPrompts"),
              path: MenuRoutes.AIPrompts,
              icon: <MailTemplate24Regular style={TemplatesIconStyle} />,
              roles: [Roles.WebAdmin],
              sx: { display: permissions?.textChannelEnabled ? "block" : "none" }
            },
            {
              title: t("DashBoardSidebar.Schedule"),
              path: MenuRoutes.Schedules,
              icon: getIcon("ScheduleMenuIcon"),
              roles: [Roles.WebAdmin]
            },
            {
              title: t("DashBoardSidebar.Events"),
              path: MenuRoutes.Events,
              icon: getIcon("EventMenuIcon"),
              roles: [Roles.WebAdmin]
            },
            {
              title: t("DashBoardSidebar.WrapUp"),
              path: MenuRoutes.WrapUp,
              icon: getIcon("WrapUpMenuIcon"),
              roles: [Roles.WebAdmin]
            },
          ],
        },
      ],
      roles: [Roles.WebAdmin, Roles.Supervisor],
      open: isSettingsOpen(),
      onClick: () => setSelectedMenuPath(settingsRoutes.join(','))
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const returnSection = (section: any) => {
    return (
      <NavSection
        key={section.items[0].title}
        pathname={location.pathname}
        sx={{
          "& + &": {
            mt: -2,
          },
          display: section.display ?? "block",
        }}
        {
        ...section
        }
      />
    );
  }

  const filteredMainMenuSections = mainMenuSections
    .filter(m => m.roles.some(r => getCurrentUserRoles().includes(r)))
    .map((section) => (
      returnSection(section)
    ));

  const content = (
    <Scrollbar options={{ suppressScrollX: true, suppressScrollY: false }} style={{ backgroundColor: "#F6F9FA" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between"
        }}
      >
        <div>
          <Box sx={{ p: 4, textAlign: "center", display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <img src="/static/CC4Teams2.png" alt="cc4allLogo" height="100px" className={classes.logoImg} />
          </Box>

          <Box className={classes.mainNavSection}>
            {filteredMainMenuSections}
          </Box>
        </div>

        <Box className={classes.secondaryNavSection}>
          {secondaryMenuSections
            .filter(m => m.roles.some(r => getCurrentUserRoles().includes(r)))
            .map((section) => (
              returnSection(section)
            ))}
        </Box>
      </Box>
    </Scrollbar>
  );

  return (
    <>

      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            width: sidebarWidth,
            display: { lg: 'none', xs: 'block' }
          },
        }}
        variant="temporary"
      >
        {content}
      </Drawer>


      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            width: sidebarWidth,
            display: { xs: 'none', lg: 'block' }
          },
        }}
        variant="persistent"
      >
        {content}
      </Drawer>

    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
